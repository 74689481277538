<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Widgets</v-card-title>
        <v-card-subtitle>
            Personnalisez votre tableau de bord avec les widgets disponibles.<br>
            Déplacez vos widgets pour changer leur ordre, changez leur taille parmis celles disponibles ou bien supprimez-les.
        </v-card-subtitle>
        <v-card-text>
            <v-text-field class="pa-5" append-icon="mdi-magnify" label="Rechercher" single-line hide-details></v-text-field>
            <v-btn class="ml-5 mb-5" color="primary">+ Ajouter</v-btn>
            <v-divider></v-divider>
            <v-list>
                <draggable v-model="settings.preferencies.widgets" ghost-class="ghost" dragClass="chosenWidget" animation="500" easing="cubic-bezier(1, 0, 0, 1)" :class="'pa-3 widgetGrid  drag '" :disabled="false">
                    <v-list-item v-for="(widget, index) in settings.preferencies.widgets" :key="widget.id" outlined>
                        <v-list-item-title class="title">{{ `#${index + 1} - ${widget.name}` }}</v-list-item-title>
                        <v-select class="pt-0 mt-0" style="width:200px" v-model="widget.size" :items="widget.sizeAvailable" item-text="size" item-value="size" menu-props="auto" label="Select" hide-details single-line>
                            <template slot="selection" slot-scope="data">
                                {{ sizeName(data.item) }}
                            </template>
                            <template slot="item" slot-scope="data">
                                {{ sizeName(data.item) }}
                            </template>
                        </v-select>
                        <v-btn icon class="ml-2" color="red" @click="deleteWidget(widget)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item>
                </draggable>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import { mapMutations } from 'vuex';

export default {
    name : 'Widgets',
    components : {
        draggable
    },
    data() {
        return {
            settings : JSON.parse(JSON.stringify(this.$store.state.settings)),
            settings_backup : this.$store.state.settings,
            sizes : [
                { label : 'Très petit', value : 'xs', gridSize : '1x1' },
                { label : 'Petit', value : 'sm', gridSize : '2x1' },
                { label : 'Moyen', value : 'md', gridSize : '3x1' },
                { label : 'Large', value : 'lg', gridSize : '2x2' },
                { label : 'Grand', value : 'xl', gridSize : '2x3' },
                { label : 'Très large', value : 'xlg', gridSize : '2x4' },
                { label : 'Très grand', value : 'xmd', gridSize : '3x4' },
            ],
        }
    },
    methods: {
        ...mapMutations(['setSettings']),
        sizeName(size) {
            if(size === 'xs') return 'Très petit (1x1)';
            else if(size === 'sm') return 'Petit (2x1)';
            else if(size === 'md') return 'Moyen (3x1)';
            else if(size === 'lg') return 'Large (2x3)';
            else if(size === 'xl') return 'Grand (3x3)';
            else if(size === 'xlg') return 'Très large (2x4)';
            else if(size === 'xmd') return 'Très grand (3x4)';
        },
        deleteWidget(widget) {
            this.settings = this.settings.filter(el => { return el.id !== widget.id });
        },
        saveSettings() {
            this.setSettings(this.settings);
            this.settings_backup = this.$store.state.settings;
            this.settings = JSON.parse(JSON.stringify(this.$store.state.settings))
        },
        resetSettings() {
            this.settings = JSON.parse(JSON.stringify(this.settings_backup));
        },
        isChanges () {
            if (JSON.stringify(this.settings_backup) !== JSON.stringify(this.settings)) {
                return false;
            } 
            else return true;            
        }
    },
}
</script>

<style lang="scss" scoped>
    .v-list-item {
        margin-bottom: 5px;
        background-color:var(--v-bg_card_app-darken1);
    }
    .ghost {
        opacity: 0.8;
        border: 2px solid var(--v-nav_main-base); 
    }
    .chosenWidget {
        border: 2px solid yellow;
        opacity: 0;
        background: yellowgreen;
    }
</style>